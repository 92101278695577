import { createAction, props } from '@ngrx/store';
import {
  GraphSidebarOptions,
  GraphSidebarOptionsForGraph,
} from '../../../features/graphs/graph-sidebar';

export const setGraphSidebarOptionsForGraph = createAction(
  '[Graph Sidebar Options] Set Graph Sidebar Options For Graph',
  props<{ id: string; options: GraphSidebarOptionsForGraph }>(),
);

export const fetchAllGraphSidebarOptions = createAction(
  '[Graph Sidebar Options] Fetch All Graph Sidebar Options',
);

export const setAllGraphSidebarOptions = createAction(
  '[Graph Sidebar Options] Set All Graph Sidebar Options',
  props<{
    graphSidebarOptions: GraphSidebarOptions;
  }>(),
);
