import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { HierarchyNode, ZoomTransform } from 'd3';
import { ColorPaletteService } from 'src/app/core/color/color-palette.service';
import { toRadians } from 'src/app/shared/utils/math-utils';
import { ExcludeMethods, TypedChange, TypedChanges } from 'src/app/shared/utils/types';
import { ExportableChart } from '../exportable-chart';
import { TreeGraphBranchTransform, TreeGraphData } from '../graph-sidebar';
import { D3GraphCanvas } from '../d3-graphs-shared/d3-graph-canvas';
import {
  D3ColorFunction,
  defaultGraphCircularTreeConfig,
  GraphCircularTreeConfig,
  LabelPositionData,
  TreeGraphColoringMetadataConfig,
  TreeGraphDefaultTooltips,
  TreeGraphHeatmapConfig,
  TreeGraphLegendConfig,
  TreeGraphTipLabelConfig,
} from './graph-circular-tree.model';
import { GraphCircularTreeLegend } from './legend/graph-circular-tree-legend';
import { NgsZoomService } from '../../../core/ngs/ngs-graphs/graph-zoom/ngs-zoom.service';
import {
  AbsoluteDimensions,
  GraphZoomContainerComponent,
} from '../graph-zoom-container/graph-zoom-container.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-circular-tree-graph',
  templateUrl: './graph-circular-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GraphZoomContainerComponent, AsyncPipe],
})
export class GraphCircularTreeComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, ExportableChart
{
  @Input() data: TreeGraphData;
  @Input() tipLabelConfig: TreeGraphTipLabelConfig;
  @Input() defaultTooltips: TreeGraphDefaultTooltips;
  @Input() coloringMetadataConfig: TreeGraphColoringMetadataConfig;
  @Input() heatmapConfig: TreeGraphHeatmapConfig;
  @Input() branchTransform: TreeGraphBranchTransform;
  @Input() autoColorBranches: boolean;
  @Input() legendConfig: TreeGraphLegendConfig;
  @ViewChild(GraphZoomContainerComponent, { static: true })
  zoomContainer: GraphZoomContainerComponent;

  @HostBinding('class') readonly hostClass =
    'd-flex flex-grow-1 flex-shrink-1 overflow-hidden min-w-0';

  static MAX_HEATMAP_ROWS = 3;

  private tipLabel: d3.Selection<
    d3.BaseType | SVGTextElement,
    d3.HierarchyNode<TreeGraphData>,
    SVGGElement,
    undefined
  >;

  private link: d3.Selection<
    d3.BaseType | SVGPathElement,
    d3.HierarchyLink<TreeGraphData>,
    SVGGElement,
    undefined
  >;

  private tipDot: d3.Selection<
    d3.BaseType | SVGCircleElement,
    d3.HierarchyNode<TreeGraphData>,
    SVGGElement,
    undefined
  >;

  private heatmapRows: d3.Selection<
    d3.BaseType | SVGGElement,
    TreeGraphColoringMetadataConfig,
    d3.BaseType | SVGGElement,
    undefined
  >;

  private heatmapCells: d3.Selection<
    d3.BaseType | SVGPathElement,
    d3.HierarchyNode<TreeGraphData>,
    d3.BaseType | SVGGElement,
    TreeGraphColoringMetadataConfig
  >;

  private heatmapContainer: d3.Selection<SVGGElement, undefined, null, undefined>;

  private svg: d3.Selection<SVGSVGElement, undefined, null, undefined>;
  private graphTooltipContainer: d3.Selection<SVGForeignObjectElement, undefined, null, undefined>;
  ZOOM_MIN = 4 / 5;
  ZOOM_MAX = 50;

  private readonly config: GraphCircularTreeConfig = defaultGraphCircularTreeConfig();
  private canvas: D3GraphCanvas;
  private legend: GraphCircularTreeLegend;
  private nodeColorFn: D3ColorFunction;
  private nodeColorGroupsAppearanceCount: Map<string | number, number> = new Map();

  /**
   * The inner radius of the graph. This is where the links or heatmap stop and the labels start.
   * @private
   */
  private innerRadius = 300;

  private get heatmapRowHeight() {
    const { scale } = this.zoomContainer.getAbsoluteDimensionsForFit();
    return 20 / (scale ?? 1);
  }

  /**
   * The outer radius of the graph. This is where the labels stop.
   * @private
   */
  private outerRadius = 960;
  private root: d3.HierarchyNode<TreeGraphData>;
  private graphMaxDepth: number;
  private graphMaxRadius: number;

  constructor(
    private readonly colorPaletteService: ColorPaletteService,
    private readonly ngsZoomService: NgsZoomService,
  ) {}

  ngOnInit(): void {
    this.canvas = new D3GraphCanvas(this.config.labelFontSize);
    this.legend = new GraphCircularTreeLegend(this.config, this.canvas);
  }

  ngOnDestroy(): void {
    this.canvas?.destroy();
  }

  ngAfterViewInit(): void {
    this.renderGraph();
    // This is just to pass the karma test.
    new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        const rect = this.zoomContainer.getContainerRect();
        if (rect.width > 0 && rect.height > 0) {
          this.handleResize();
        }
      });
    }).observe(this.zoomContainer.getContainer().nativeElement);
  }

  resize() {}

  ngOnChanges(changes: TypedChanges<ExcludeMethods<GraphCircularTreeComponent>>) {
    if (changes.data?.firstChange) {
      return;
    }

    const dataChange = this.hasSubsequentChange(changes.data);
    if (dataChange) {
      this.renderGraph(true);
      this.handleResize();
      this.zoomContainer.zoomToFit();
    }

    const heatmapChange = this.hasPropertyChange(
      changes.heatmapConfig,
      (cfg) => cfg.heatmapRowConfigs,
    );

    if (heatmapChange) {
      this.reloadHeatmaps();
      this.colorHeatmapRows();
      this.updateHeatmapVisibility();
      this.addGraphTooltip(this.heatmapCells, true);
    }

    const labelChange =
      this.tipLabel && this.hasPropertyChange(changes.tipLabelConfig, (cfg) => cfg?.name);
    if (labelChange) {
      this.updateTipLabelText();
    }
    const defaultTooltipsChange = this.hasSubsequentChange(changes.defaultTooltips);

    const metadataChange = this.hasSubsequentChange(changes.coloringMetadataConfig);
    if (labelChange || metadataChange || heatmapChange || defaultTooltipsChange) {
      this.computeTooltips();
    }

    const legendChange =
      labelChange ||
      metadataChange ||
      this.hasSubsequentChange(changes.autoColorBranches) ||
      this.hasPropertyChange(changes.legendConfig, (cfg) => cfg?.metadataColoringConfig);
    if (legendChange) {
      this.colorNodesAndBranches();
      this.colorHeatmapRows();
      this.renderLegendForNodeGroups(this.legendConfig.metadataColoringConfig);
    }

    const tipLabelLengthChange =
      this.hasSubsequentChange(changes.tipLabelConfig) &&
      this.hasPropertyChange(changes.tipLabelConfig, (cfg) => cfg?.maxChars);
    if (tipLabelLengthChange) {
      this.updateTipLabelText();
    }

    const tipLabelVisibilityChange =
      this.tipLabel && this.hasPropertyChange(changes.tipLabelConfig, (cfg) => cfg?.show);
    if (tipLabelVisibilityChange) {
      const visibility = this.tipLabelConfig.show ? 'visible' : 'hidden';
      this.tipLabel.attr('visibility', visibility);
    }

    if (legendChange || this.hasPropertyChange(changes.legendConfig, (cfg) => cfg?.show)) {
      this.updateLegendVisibility();
    }

    const heatmapVisibilityChange = this.hasPropertyChange(
      changes.heatmapConfig,
      (cfg) => cfg?.show,
    );

    if (heatmapVisibilityChange) {
      this.updateHeatmapVisibility();
    }

    const isGraphNeedResize =
      dataChange ||
      labelChange ||
      metadataChange ||
      legendChange ||
      tipLabelLengthChange ||
      tipLabelVisibilityChange ||
      heatmapVisibilityChange ||
      heatmapChange;

    if (isGraphNeedResize) {
      this.handleResize();
    }

    const t = d3.transition().duration(600);

    if (changes.branchTransform && this.link) {
      this.link.transition(t).attr('d', this.getBranchPath());
    }

    if (changes.branchTransform && this.tipLabel) {
      this.tipLabel.transition(t).attr('transform', this.getLabelTransform());
      this.tipDot.transition(t).attr('transform', this.getTipTransform());
      this.updateLabelVisibility();
    }
  }

  private hasSubsequentChange(change?: TypedChange<any>): boolean {
    return change != null && !change.firstChange;
  }

  private hasPropertyChange<T>(
    change: TypedChange<T> | undefined,
    getProperty: (value?: T) => unknown,
  ) {
    return change != null && getProperty(change.currentValue) !== getProperty(change.previousValue);
  }

  private updateTipLabelText() {
    this.tipLabel.text((d) => this.getNodeLabel(d.data.id));
  }

  downloadImage(documentName?: string) {
    const { minX, maxX, minY, maxY } = this.zoomContainer.getAbsoluteDimensionsForFit();
    const transform = this.zoomContainer.zoomTransform;
    this.svg.call(this.zoomContainer.getZoom().transform, d3.zoomIdentity);
    const graphSVG = this.svg.node().cloneNode(true) as SVGSVGElement;
    const IMAGE_X = 1500;
    const IMAGE_Y = (IMAGE_X * (maxY - minY)) / (maxX - minX);
    graphSVG.setAttribute('width', `${IMAGE_X}px`);
    graphSVG.setAttribute('height', `${IMAGE_Y}px`);
    graphSVG.setAttribute('viewBox', `${minX} ${minY} ${maxX - minX} ${maxY - minY}`);

    if (this.legendConfig.show) {
      const legendSvgNode = this.legend.nativeElement.cloneNode(true) as SVGSVGElement;
      const legendSVGBounds = legendSvgNode.getBBox();
      legendSvgNode.setAttribute('style', `margin-left: 0px; overflow: visible; display: block;`);
      const height = Math.max(IMAGE_Y, legendSVGBounds.height);
      this.canvas
        .downloadSvgsAsImage(
          documentName,
          [
            { svg: graphSVG, x: 0, y: 0 },
            {
              svg: legendSvgNode,
              x: IMAGE_X,
              y: (height - legendSVGBounds.height) / 2,
            },
          ],
          IMAGE_X + this.legend.width + 2 * this.config.legendLeftMargin,
          height,
        )
        .then(() => {
          graphSVG.remove();
          legendSvgNode.remove();
        });
    } else {
      this.canvas
        .downloadSvgsAsImage(documentName, [{ svg: graphSVG, x: 0, y: 0 }], IMAGE_X, IMAGE_Y)
        .then(() => {
          graphSVG.remove();
        });
    }
    this.svg.call(this.zoomContainer.getZoom().transform, transform);
  }

  private calculateGraphSize() {
    const zoomDims = this.zoomContainer.getDimensionsForZoom();

    this.graphTooltipContainer?.attr(
      'transform',
      `translate(${-zoomDims.width / 2}, ${-zoomDims.height / 2})`,
    );
    this.svg
      .attr('width', zoomDims.width)
      .attr('height', zoomDims.height)
      .attr('viewBox', [
        -zoomDims.width / 2,
        -zoomDims.height / 2,
        zoomDims.width,
        zoomDims.height,
      ]);
    const { x, y, k } = this.zoomContainer.zoomTransform;
    this.zoomContainer.setScrollState('zoom');
    this.zoomContainer.setScrollBarsToZoomPosition(x, y, k, true);
    this.zoomContainer.setScrollState('idle');
  }

  /**
   * Cluster the graph using polar co-ordinates (https://w.wiki/7dmX) where the
   * reference point is the center of the graph, and the reference angle (0
   * degrees) points to the right. Each co-ordinate has an x and a y.
   * - x is the direction (the angle in degrees from the reference angle)
   * - y is the distance from the reference point
   *
   * @returns the root {@link d3.HierarchyPointNode}
   */
  private clusterGraph() {
    const cluster = d3
      .cluster<TreeGraphData>()
      .size([360, this.innerRadius])
      .separation((a, b) => (a.parent == b.parent ? 1 : 2));
    return cluster(this.root);
  }

  private calculateInnerRadius() {
    if (!this.tipLabelConfig.show && !this.heatmapConfig.show) {
      this.innerRadius = this.outerRadius - 5;
    } else {
      const labelOffset = 16;
      let newRadius = this.outerRadius;

      if (this.tipLabelConfig.show) {
        const longestLabel = this.getLongestLabel();
        const { scale } = this.zoomContainer.getAbsoluteDimensionsForFit();
        const longestLabelLength =
          this.canvas.measureText(this.formatLabel(longestLabel)).width / scale;
        newRadius = Math.max(newRadius - longestLabelLength - labelOffset, 0);
      }

      const numberOfHeatmapRows = this.heatmapConfig.heatmapRowConfigs.length;
      if (this.heatmapConfig.show && numberOfHeatmapRows > 0) {
        newRadius = Math.max(
          newRadius - numberOfHeatmapRows * this.heatmapRowHeight - labelOffset,
          0,
        );
      }

      this.innerRadius = newRadius;
    }
    this.clusterGraph();
  }

  private getLongestLabel() {
    let longestLabel = '';
    for (const label of this.tipLabelConfig.labels.values()) {
      const labelLength = label ? Math.min(this.tipLabelConfig.maxChars, label.length) : -1;
      if (labelLength > longestLabel.length) {
        longestLabel = label;
      }
    }
    return longestLabel;
  }

  private formatLabel(label?: string) {
    if (!label) {
      return '';
    }

    if (label.length <= this.tipLabelConfig.maxChars) {
      return label;
    }

    if (this.tipLabelConfig.maxChars <= 3) {
      return label.slice(0, this.tipLabelConfig.maxChars);
    }

    return label.slice(0, this.tipLabelConfig.maxChars - 3) + '...';
  }

  private handleResize() {
    const self = this;
    this.calculateGraphSize();
    this.calculateInnerRadius();
    this.link.attr('d', this.getBranchPath());
    this.tipLabel.attr('transform', this.getLabelTransform());
    this.tipDot.attr('transform', this.getTipTransform());
    this.heatmapRows.selectAll('path').attr('d', function (d) {
      const currentNode = this as SVGPathElement;
      const parentNode = currentNode.parentNode as SVGGElement;
      const rowIndex = parseInt(d3.select(parentNode).attr('data-index'));
      return self.getHeatmapTransform(rowIndex)(d);
    });
    this.updateLabelVisibility();
    const { scaleX, scaleY } = this.zoomContainer.getAbsoluteDimensionsForFit();
    if (
      scaleX > this.zoomContainer.getZoomLevelValue() ||
      scaleY > this.zoomContainer.getZoomLevelValue()
    ) {
      this.zoomContainer.zoomToFit();
    }
    const { x, y, k } = this.zoomContainer.zoomTransform;
    this.zoomContainer.setScrollState('zoom');
    this.zoomContainer.setScrollBarsToZoomPosition(x, y, k, true);
    this.zoomContainer.setScrollState('idle');
  }

  private findLeafMaxValue(predicate: (a: HierarchyNode<TreeGraphData>) => number) {
    const leaves = this.root.leaves();
    let max = predicate(leaves[0]);
    for (const leaf of leaves) {
      const value = predicate(leaf);
      if (value > max) {
        max = value;
      }
    }
    return max;
  }

  private calculateGraphMaxDepthAndRadius() {
    this.graphMaxDepth = this.findLeafMaxValue((leaf) => leaf.depth);
    this.graphMaxRadius = this.findLeafMaxValue((leaf) => (leaf as any).radius);
  }

  private renderGraph(reload = false) {
    this.root = d3.hierarchy(this.data, (node) => node.children);

    // Set the radius of each node by recursively summing and scaling the distance from the root.
    const setRadius = (d: HierarchyNode<TreeGraphData>, y0: number, k: number) => {
      (d as any).radius = (y0 += d.data.branch_length) * k;
      if (d.children) d.children.forEach((d) => setRadius(d, y0, k));
    };

    // Compute the maximum cumulative length of any node in the tree.
    const maxLength = (d: HierarchyNode<TreeGraphData>): number => {
      return d.data.branch_length + (d.children ? d3.max(d.children, maxLength) : 0);
    };

    this.clusterGraph();
    setRadius(
      this.root,
      (this.root.data.branch_length = 0),
      this.innerRadius / maxLength(this.root),
    );
    this.calculateGraphMaxDepthAndRadius();

    if (reload) {
      this.svg.node().remove();
      this.legend.nativeElement.remove();
      this.legend = new GraphCircularTreeLegend(
        this.config,
        this.canvas,
        this.coloringMetadataConfig.isCategorical ? 'categorical' : 'continuous',
      );
    }
    this.ngsZoomService.registerZoomControls(this.zoomContainer);
    this.svg = d3
      .create('svg')
      .attr('font-family', 'sans-serif')
      .attr('font-size', this.config.labelFontSize)
      .style('position', 'absolute')
      .style('flex', '0 0 auto');
    this.zoomContainer.setSVG(this.svg);
    this.zoomContainer.setDimensions(
      (container) => new CircularTreeDimensions(container, this.outerRadius),
    );
    const zoomDims = this.zoomContainer.getDimensionsForZoom();
    this.calculateGraphSize();

    const gLink = this.svg.append('g');
    const gTipLabel = this.svg.append('g');
    const gTipDot = this.svg.append('g');
    const gHeatmap = this.svg.append('g');
    const tooltipContainer = this.svg.append('foreignObject');

    this.link = gLink
      .attr('id', 'branches')
      .attr('fill', 'none')
      .attr('stroke', '#000')
      .attr('stroke-width', 1.5)
      .selectAll('path')
      .data(this.root.links())
      .join('path')
      .each(function (d: any) {
        d.target.linkNode = this;
      })
      .attr('d', this.getBranchPath())
      .attr('stroke', (d: any) => d.target.color);

    this.tipLabel = gTipLabel
      .selectAll('text')
      .data(this.root.leaves())
      .join('text')
      .attr('fill', '#000')
      .attr('stroke', 'none')
      .attr('stroke-width', 0)
      .attr('dy', '.31em')
      .attr('transform', this.getLabelTransform())
      .attr('text-anchor', (d: any) => (this.isOnLeftHandSide(d.x) ? 'end' : 'start'))
      .text((d) => this.getNodeLabel(d.data.id))
      .on('mouseover', (_, d) => {
        const dataId = d.data.id;
        const circle = this.svg.node().querySelector(`circle[data-id="${dataId}"]`);
        d3.select(circle).attr('opacity', '1');
      })
      .on('mouseleave', (_, d) => {
        const dataId = d.data.id;
        const circle = this.svg.node().querySelector(`circle[data-id="${dataId}"]`);
        d3.select(circle).attr('opacity', '0');
      });

    this.tipDot = gTipDot
      .selectAll('circle')
      .data(this.root.leaves())
      .join('circle')
      .attr('data-id', (d) => d.data.id)
      .attr('r', '5')
      .attr('stroke', '#000')
      .attr('stroke-width', 1)
      .attr('opacity', '0')
      .attr('transform', this.getTipTransform())
      .on('mouseover.dot', function () {
        const circle = this as SVGCircleElement;
        d3.select(circle).attr('opacity', '1');
      })
      .on('mouseleave.dot', function () {
        const circle = this as SVGCircleElement;
        d3.select(circle).attr('opacity', '0');
      });

    this.heatmapContainer = gHeatmap.attr('id', 'heatmaps');
    this.reloadHeatmaps();

    const style = `
      .graph-circular-tree-tooltip {
        background: white;
        position: absolute;
        border: 1px solid black;
        border-radius: 2px;
        visibility: hidden;
        font-size: 12px;
        padding: 2px 5px;
        max-width: 500px;
        overflow: visible;
        word-break: break-all;
        word-wrap: break-word;
        z-index: 1000;
      }
    `;
    this.svg.append('style').text(style);

    this.graphTooltipContainer = tooltipContainer
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('pointer-events', 'none')
      .attr('transform', `translate(${-zoomDims.width / 2}, ${-zoomDims.height / 2})`);
    this.calculateGraphSize();
    this.calculateInnerRadius();
    this.computeTooltips();
    this.addGraphTooltip(this.tipDot);
    this.addGraphTooltip(this.tipLabel);
    this.addGraphTooltip(this.heatmapCells, true);

    if (!this.tipLabelConfig.show) {
      this.tipLabel.attr('visibility', 'hidden');
    }
    this.zoomContainer.setScrollEventListeners(true);
    this.zoomContainer.setZoomBehaviour(
      this.createZoomBehaviour([gLink, gTipLabel, gTipDot, gHeatmap]),
    );
    this.zoomContainer.setKeyEventListeners();
    this.svg.call(this.zoomContainer.getZoom());

    this.colorNodesAndBranches();
    this.colorHeatmapRows();
    this.updateLegendVisibility();
    this.updateHeatmapVisibility();
    this.renderLegendForNodeGroups(this.legendConfig.metadataColoringConfig);

    this.zoomContainer.getContainer().nativeElement.append(this.svg.node());
    this.zoomContainer.getContainer().nativeElement.append(this.legend.nativeElement);
    this.zoomContainer.zoomToFit();
  }

  private createZoomBehaviour(elts: any[]) {
    const { minX, minY, maxX, maxY } = this.zoomContainer.getAbsoluteDimensionsForBounds();
    return d3
      .zoom()
      .scaleExtent([this.ZOOM_MIN, this.ZOOM_MAX])
      .translateExtent([
        [minX, minY],
        [maxX, maxY],
      ])
      .filter((event: UIEvent) => !(event.type == 'dblclick' && (<MouseEvent>event).shiftKey))
      .on('start', () => {
        this.zoomContainer.setScrollState(
          this.zoomContainer.getScrollState() === 'scroll' ? 'scroll' : 'zoom',
        );
      })
      .on('zoom', (element: { transform: ZoomTransform }) => {
        const { transform } = element;
        const { x, y, k } = transform;
        if ([x, y, k].some((num) => num === null || num === undefined || Number.isNaN(num))) {
          return;
        }
        for (let elt of elts) {
          elt.attr('transform', transform as any);
        }
        this.svg.attr('font-size', this.config.labelFontSize / k);
        this.link.attr('stroke-width', 1.5 / k);
        this.tipDot.attr('r', 5 / k).attr('stroke-width', 1 / k);
        this.tipLabel.attr('transform', this.getLabelTransform());
        this.heatmapCells.attr('stroke-width', 2 / k);
        this.zoomContainer.setCurrentZoom(k);
        this.updateLabelVisibility();
        if (this.zoomContainer.getScrollState() !== 'scroll') {
          this.zoomContainer.setScrollBarsToZoomPosition(x, y, k, true);
        }
      })
      .on('end', () => {
        this.zoomContainer.setScrollState('idle');
      });
  }

  private reloadHeatmaps() {
    const self = this;
    const numberOfRows = this.heatmapConfig.heatmapRowConfigs.length;
    this.heatmapRows = this.heatmapContainer
      .selectAll('g')
      .data(this.heatmapConfig.heatmapRowConfigs.values())
      .join('g')
      // This is so the top most config is the outermost ring. This help users better visualise it.
      .attr('data-index', (_, i) => numberOfRows - i - 1);
    this.heatmapCells = this.heatmapRows
      .selectAll('path')
      .data(this.root.leaves())
      .join('path')
      .attr('fill', '#fff')
      .attr('stroke', 'none')
      .attr('d', function (d) {
        const currentNode = this as SVGPathElement;
        const parentNode = currentNode.parentNode as SVGGElement;
        const rowIndex = parseInt(d3.select(parentNode).attr('data-index'));
        return self.getHeatmapTransform(rowIndex)(d);
      });
  }

  private computeTooltips() {
    const setTooltip = (
      d: any,
      coloringEnabled: boolean,
      coloringConfig: TreeGraphColoringMetadataConfig,
    ) => {
      const tooltipLines: string[] = [];
      const defaultTooltipFieldsForThisNode = this.defaultTooltips?.get(d.data.id) ?? {};
      for (const field in defaultTooltipFieldsForThisNode) {
        tooltipLines.push(`${field}:\n\t${defaultTooltipFieldsForThisNode[field]}`);
      }
      if (
        this.tipLabelConfig.name &&
        !(this.tipLabelConfig.name in defaultTooltipFieldsForThisNode)
      ) {
        const labelValue = this.tipLabelConfig.labels.get(d.data.id) ?? 'Unknown';
        tooltipLines.push(`${this.tipLabelConfig.name}:\n\t${labelValue}`);
      }
      if (coloringEnabled && coloringConfig.name) {
        const metadataValue = coloringConfig.values.get(d.data.id)?.value ?? 'Unknown';
        tooltipLines.push(`${coloringConfig.name}:\n\t${metadataValue}`);
      }
      d[`tooltip_${coloringConfig.name}`] = tooltipLines.join('\n');
    };
    this.tipLabel
      .attr('metadataName', this.coloringMetadataConfig.name)
      .each((d) => setTooltip(d, this.autoColorBranches, this.coloringMetadataConfig));
    this.tipDot
      .attr('metadataName', this.coloringMetadataConfig.name)
      .each((d) => setTooltip(d, this.autoColorBranches, this.coloringMetadataConfig));

    const self = this;
    this.heatmapCells.each(function (d) {
      const heatmapRow = (this as SVGPathElement).parentNode as SVGGElement;
      const rowColoringConfig = d3.select(heatmapRow).datum() as TreeGraphColoringMetadataConfig;
      d3.select(this).attr('metadataName', rowColoringConfig.name);
      setTooltip(d, self.heatmapConfig.show, rowColoringConfig);
    });
  }

  private colorNodesAndBranches() {
    if (this.autoColorBranches) {
      this.groupNodes();
      this.tipDot.attr('stroke', '#000').attr('fill', (d: any) => this.nodeColorFn(d.group));
      this.link.attr('stroke', (l: any) => this.nodeColorFn(l.target.group));
      this.tipLabel.attr('fill', (d: any) => this.nodeColorFn(d.group));
    } else {
      this.resetNodeAndBranchesColor();
    }
  }

  private colorHeatmapRows() {
    const self = this;
    this.heatmapRows.each(function (config) {
      const row = d3.select(this as SVGGElement).selectAll('path');
      const groups = new Set<string>();
      self.root.leaves().forEach((node: any) => {
        const group = config.values.get(node.data.id)?.group ?? null;
        groups.add(group as any);
      });
      const colorFn = self.colorPaletteService.getColorFunction(
        config.colorPalette,
        [...groups],
        config.isCategorical,
      );
      row.attr('fill', (d: any) => {
        const group = config.values.get(d.data.id)?.group ?? null;
        return colorFn(group as any);
      });
    });
  }

  private resetNodeAndBranchesColor() {
    this.tipDot.attr('stroke', '#000').attr('fill', '#000');
    this.tipLabel.attr('stroke', '#000').attr('fill', '#000');
    this.link.attr('stroke', '#000');
  }

  private groupNodes() {
    this.nodeColorGroupsAppearanceCount.clear();

    this.root.leaves().forEach((node: any) => {
      const group = this.detectNodeGroup(node.data.id, this.coloringMetadataConfig);
      node.group = group;
      this.nodeColorGroupsAppearanceCount.set(
        group,
        1 + (this.nodeColorGroupsAppearanceCount.get(group) ?? 0),
      );
    });
    const groups = Array.from(this.nodeColorGroupsAppearanceCount.keys());
    this.nodeColorFn = this.colorPaletteService.getColorFunction(
      this.coloringMetadataConfig.colorPalette,
      groups,
      this.coloringMetadataConfig.isCategorical,
    );

    this.root.leaves().forEach((node: any) => {
      this.propagateGroupForNode(node);
    });
  }

  private calculateNodeGroupsAppearances(config: TreeGraphColoringMetadataConfig) {
    const result = new Map<string | number, number>();
    this.root.leaves().forEach((node: any) => {
      const group = this.detectNodeGroup(node.data.id, config);
      result.set(group, 1 + (result.get(group) ?? 0));
    });
    return result;
  }

  private propagateGroupForNode(node: any) {
    if (!node.parent) {
      return;
    }

    if (node.parent.children.every((n: any) => n.group === node.group)) {
      node.parent.group = node.group;
    } else {
      node.parent.group = null;
    }
    this.propagateGroupForNode(node.parent);
  }

  private detectNodeGroup(
    nodeID: string,
    config: TreeGraphColoringMetadataConfig,
  ): string | number | null {
    return config.values.get(nodeID)?.group ?? null;
  }

  private renderLegendForNodeGroups(config?: TreeGraphColoringMetadataConfig) {
    if (!config) {
      return;
    }
    const nodeGroupAppearanceCount = this.calculateNodeGroupsAppearances(config);
    const groups = Array.from(nodeGroupAppearanceCount.keys());
    const groupsByAppearanceCount = groups.sort(
      (groupA, groupB) =>
        nodeGroupAppearanceCount.get(groupB) - nodeGroupAppearanceCount.get(groupA),
    );
    const colorFn = this.colorPaletteService.getColorFunction(
      config.colorPalette,
      groups,
      config.isCategorical,
    );
    this.legend.setLegendType(config.isCategorical ? 'categorical' : 'continuous');
    this.legend.render(config.name, groupsByAppearanceCount, colorFn);
  }

  /**
   * Show or hide legend when graph update
   */
  private updateLegendVisibility() {
    this.legend.visible = this.legendConfig.show;
  }

  /**
   * Hides tip labels so that there is no visible overlapping text on the graph.
   */
  private updateLabelVisibility() {
    const labelsToHide = this.getLabelsToHide(this.root.leaves() as any);
    this.tipLabel.attr('display', (d) => (labelsToHide.has(d.data.id) ? 'none' : 'inherit'));
  }

  private updateHeatmapVisibility() {
    const visibility = this.heatmapConfig.show ? 'visible' : 'hidden';
    this.heatmapRows.attr('visibility', visibility);
  }

  /**
   * Finds the labels that should be hidden to ensure that there is no
   * visible overlapping text on the graph.
   *
   * @param leaves the leaf nodes of the tree graph
   * @returns a set of data IDs corresponding to labels that should be hidden
   */
  private getLabelsToHide(
    leaves: (d3.HierarchyPointNode<TreeGraphData> & { radius: number })[],
  ): Set<string> {
    const labelsToHide = new Set<string>();
    if (leaves.length <= 1) {
      return labelsToHide;
    }
    let prevLeafData = this.getLabelPositionData(leaves[leaves.length - 1]);
    for (const leaf of leaves) {
      const currentLeafData = this.getLabelPositionData(leaf);
      if (this.labelsOverlap(currentLeafData, prevLeafData)) {
        labelsToHide.add(leaf.data.id);
      } else {
        prevLeafData = currentLeafData;
      }
    }
    return labelsToHide;
  }

  /**
   * Gets data about the leaf node label's position.
   *
   * @param d a leaf node
   * @returns data about its position in polar co-ordinate space
   */
  private getLabelPositionData(
    d: d3.HierarchyPointNode<TreeGraphData> & { radius: number },
  ): LabelPositionData {
    const polarCoord = { direction: toRadians(d.x), distance: this.getDistanceFromCenter(d) };
    const { k } = this.zoomContainer.zoomTransform;
    const { scale } = this.zoomContainer.getAbsoluteDimensionsForFit();
    // This could be stored on the node object to improve performance
    const textMetrics = this.canvas.measureText(this.getNodeLabel(d.data.id), {
      fontWeight: 'normal',
      fontSize: `${this.config.labelFontSize / k}px`,
    });
    const labelHeightDelta =
      (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) / 2;
    const rayRange = {
      start: polarCoord.distance,
      end: polarCoord.distance + textMetrics.width / scale,
    };
    return { id: d.data.id, polarCoord, labelHeightDelta, rayRange };
  }

  /**
   * Detects whether two labels overlap with each other. This is similar to the
   * AABB collision algorithm, but adapted to polar co-ordinate space.
   * Implementation notes are in the method body.
   *
   * @param a the first label
   * @param b the second label
   * @returns true if they overlap
   */
  labelsOverlap(a: LabelPositionData, b: LabelPositionData): boolean {
    // If the labels do not overlap in the distance axis, no need to check the angle axis
    if (a.id === b.id || a.rayRange.start >= b.rayRange.end || b.rayRange.start >= a.rayRange.end) {
      return false;
    }
    // First, calculate the degrees that each label occupies on the angle axis
    // at the distance of the possible collision point. We achieve this by
    // by forming a right-angled triangle for each label.
    // See diagram: https://upload.wikimedia.org/wikipedia/commons/6/6f/Rtriangle.svg
    // Point A is the origin of the graph, point C is the vertical center of the
    // label at the possible collision point, and point B is aligned with the
    // top of the label. Angle ACB is the right angle. Angle BAC is the unknown
    // angle - the number of degrees that the label occupies on the angle axis,
    // measured from its center point to its edge.
    // After calculating this angle for both labels, we add them together, which
    // gives us the minimum angle between the labels' center points so that they
    // do not overlap.

    // Use the start of the furthest away label to detect the collision, as that
    // is the closest point at which the labels may overlap.
    // On the linked diagram this is line b, adjacent to the unknown angle
    const distance = Math.max(a.polarCoord.distance, b.polarCoord.distance);
    // labelHeightDelta is line a on the diagram, opposite to the unknown angle
    // tan(x) = opposite/adjacent
    const angleA = Math.atan(a.labelHeightDelta / distance);
    const angleB = Math.atan(b.labelHeightDelta / distance);
    const minNonOverlappingAngle = angleA + angleB;
    return this.deltaAngle(a.polarCoord.direction, b.polarCoord.direction) < minNonOverlappingAngle;
  }

  /**
   * Returns the minimum difference between two angles.
   *
   * @param a the first angle (in radians)
   * @param b the second angle (in radians)
   * @returns the difference between the two angles
   */
  private deltaAngle(a: number, b: number) {
    let max: number;
    let min: number;
    if (a > b) {
      max = a;
      min = b;
    } else {
      max = b;
      min = a;
    }
    const angle = max - min;
    if (angle <= Math.PI) {
      return angle;
    }
    return 2 * Math.PI - max + min;
  }

  /**
   * Add a tooltip for a d3 selection of elements in graph. The content of the tooltip is read from the node's `tooltip` property.
   *
   * @param selection Any d3 selection.
   * @param addHoverBorder
   * @private
   */
  private addGraphTooltip(
    selection: d3.Selection<
      d3.BaseType,
      d3.HierarchyNode<TreeGraphData>,
      d3.BaseType | SVGGElement,
      undefined
    >,
    addHoverBorder = false,
  ) {
    const tooltip = this.graphTooltipContainer
      .append('xhtml:div')
      .attr('class', 'graph-circular-tree-tooltip');

    const show = (text: string, x: number, y: number) => {
      const mouseOffsetX = 10;
      const mouseOffsetY = 10;
      const viewBox = this.zoomContainer.getDimensionsForZoom();
      let usedX = viewBox.width / 2 + x + mouseOffsetX;
      let usedY = viewBox.height / 2 + y + mouseOffsetY;

      const tooltipBox = (tooltip.node() as HTMLDivElement).getBoundingClientRect();
      if (usedX > viewBox.width - tooltipBox.width) {
        usedX = viewBox.width - tooltipBox.width - mouseOffsetX;
      }

      if (usedY > viewBox.height - tooltipBox.height) {
        usedY = viewBox.height - tooltipBox.height - mouseOffsetY;
      }

      tooltip
        .text(text)
        .style('white-space', 'pre-wrap')
        .style('visibility', 'visible')
        .style('transform', `translate(${usedX}px, ${usedY}px)`);
    };

    const hide = () => {
      tooltip.style('visibility', 'hidden');
    };

    const self = this;
    selection
      .on('mousemove.tooltip', function (_, d: any) {
        const node = d3.select(this);
        const metadataName = node.attr('metadataName');
        if (d[`tooltip_${metadataName}`]) {
          const { x, y } = self.getCoordinateAt(d.x, self.getDistanceFromCenter(d));
          show(d[`tooltip_${metadataName}`], x, y);
        }

        if (addHoverBorder) {
          node.attr('stroke', '#000');
          node.raise();
        }
      })
      .on('mouseleave.tooltip', function () {
        const node = d3.select(this);
        if (addHoverBorder) {
          node.attr('stroke', 'transparent');
        }
        hide();
      });
  }

  /**
   * Returns the distance from the center point depending on the current branch
   * transform.
   */
  private getDistanceFromCenter(d: { x: number; y: number; radius: number; depth: number }) {
    if (this.branchTransform === 'noTransform') {
      const scaleRatio = this.innerRadius / this.graphMaxRadius;
      return d.radius * scaleRatio;
    }
    if (this.branchTransform === 'cladogram') {
      return d.y;
    }
    if (this.branchTransform === 'equal') {
      const scaleRatio = this.innerRadius / this.graphMaxDepth;
      return d.depth * scaleRatio;
    }
    throw new Error('Unexpected branch transform: ' + this.branchTransform);
  }

  private getBranchPath() {
    if (this.branchTransform === 'noTransform') {
      return this.linkNoTransform.bind(this);
    } else if (this.branchTransform === 'cladogram') {
      return this.linkCladogram.bind(this);
    } else if (this.branchTransform === 'equal') {
      return this.linkEqual.bind(this);
    }
  }

  private getLabelTransform() {
    if (this.branchTransform === 'noTransform') {
      return this.labelTransformNoTransform.bind(this);
    } else if (this.branchTransform === 'cladogram') {
      return this.labelTransformCladogram.bind(this);
    } else if (this.branchTransform === 'equal') {
      return this.labelTransformEqual.bind(this);
    }
  }

  private getTipTransform() {
    if (this.branchTransform === 'noTransform') {
      return this.tipTransformNoTransform.bind(this);
    } else if (this.branchTransform === 'cladogram') {
      return this.tipTransformCladogram.bind(this);
    } else if (this.branchTransform === 'equal') {
      return this.tipTransformEqual.bind(this);
    }
  }

  private getHeatmapTransform(row_index: number) {
    return (d: any) => {
      const heatmapOffset = 5;
      const radius = d.y + row_index * this.heatmapRowHeight + heatmapOffset;

      // This only work because we sorted the sequences in backend to fit with the tree layout
      // therefore, all nodes are sorted to their position in the circle (clockwise).
      const nodes = this.root.leaves();
      const currentNodeIndex = nodes.findIndex((node) => node.data.id === d.data.id);
      const nextNodeIndex = currentNodeIndex < nodes.length - 1 ? currentNodeIndex + 1 : 0;
      const previousNodeIndex = currentNodeIndex > 0 ? currentNodeIndex - 1 : nodes.length - 1;
      const nextNode = nodes[nextNodeIndex] as any;
      const previousNode = nodes[previousNodeIndex] as any;

      const constraintWithin360degree = (value: number) => ((value % 360) + 360) % 360;

      const angleBetweenCurrentAndNextNode = constraintWithin360degree(nextNode.x - d.x);
      const angleBetweenCurrentAndPreviousNode = constraintWithin360degree(d.x - previousNode.x);

      const topRadius = radius + this.heatmapRowHeight;
      let startAngle = d.x - angleBetweenCurrentAndPreviousNode / 2;
      let endAngle = d.x + angleBetweenCurrentAndNextNode / 2;

      startAngle = toRadians(startAngle);
      endAngle = toRadians(endAngle);

      const c0 = Math.cos(startAngle);
      const s0 = Math.sin(startAngle);
      const c1 = Math.cos(endAngle);
      const s1 = Math.sin(endAngle);

      const bottomLeft = {
        x: c0 * radius,
        y: s0 * radius,
      };

      const bottomRight = {
        x: c1 * radius,
        y: s1 * radius,
      };

      const topLeft = {
        x: c0 * topRadius,
        y: s0 * topRadius,
      };

      const topRight = {
        x: c1 * topRadius,
        y: s1 * topRadius,
      };

      return `
            M ${topLeft.x},${topLeft.y}
            A ${topRadius} ${topRadius} 0 0 1 ${topRight.x} ${topRight.y}
            L ${bottomRight.x},${bottomRight.y}
            A ${radius} ${radius} 0 0 0 ${bottomLeft.x} ${bottomLeft.y}
            Z`;
    };
  }

  private getNodeLabel(nodeID: string): string {
    return this.formatLabel(this.tipLabelConfig.labels.get(nodeID));
  }

  private getCoordinateAt(angle: number, radius: number) {
    const angleInRadian = toRadians(angle);
    const zoom = this.zoomContainer.zoomTransform;
    return {
      x: (zoom?.k ?? 1) * radius * Math.cos(angleInRadian) + (zoom?.x ?? 0),
      y: (zoom?.k ?? 1) * radius * Math.sin(angleInRadian) + (zoom?.y ?? 0),
    };
  }

  /**
   * Returns true if the angle points to the left of the screen. The SVG
   * co-ordinate system has 0 degrees pointing right.
   *
   * @param degrees the angle in degrees
   * @returns whether the angle is pointing left
   */
  private isOnLeftHandSide(degrees: number): boolean {
    return 90 <= degrees && degrees < 270;
  }

  /**
   * Create a link path that do these steps:
   *
   * - Move to the x, y position calculated using the startRadius and startAngle.
   * - Start an arc to connect to the x, y at endAngle and startRadius if endAngle != startAngle.
   * - Line to the x, y position calculated using the endRadius and endAngle.
   *
   * @param startAngleDegrees The starting angle of the link in degrees
   * @param startRadius The starting radius (or distance from center) of the link
   * @param endAngleDegrees The ending angle of the link in degrees
   * @param endRadius The ending radius (or distance from cnter) of the link
   * @private
   */
  private linkStep(
    startAngleDegrees: number,
    startRadius: number,
    endAngleDegrees: number,
    endRadius: number,
  ) {
    const startAngle = toRadians(startAngleDegrees);
    const endAngle = toRadians(endAngleDegrees);
    const c0 = Math.cos(startAngle);
    const s0 = Math.sin(startAngle);
    const c1 = Math.cos(endAngle);
    const s1 = Math.sin(endAngle);
    return (
      'M' +
      startRadius * c0 +
      ',' +
      startRadius * s0 +
      (endAngle === startAngle
        ? ''
        : 'A' +
          startRadius +
          ',' +
          startRadius +
          ' 0 0 ' +
          (endAngle > startAngle ? 1 : 0) +
          ' ' +
          startRadius * c1 +
          ',' +
          startRadius * s1) +
      'L' +
      endRadius * c1 +
      ',' +
      endRadius * s1
    );
  }

  /**
   * Use the angle & radius computed by d3 cluster if it's cladogram mode.
   * This is because d3 by default spread out the position of each node so
   * that it fits a circle.
   *
   * @param d A d3 HierarchyLink that connect two node
   * @private
   */
  private linkCladogram(d: any) {
    return this.linkStep(d.source.x, d.source.y, d.target.x, d.target.y);
  }

  /**
   * Use the angle computed by d3 cluster but radius computed us base on the branch length.
   *
   * @param d A d3 HierarchyLink that connect two node
   * @private
   */
  private linkNoTransform(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxRadius;
    return this.linkStep(
      d.source.x,
      d.source.radius * scaleRatio,
      d.target.x,
      d.target.radius * scaleRatio,
    );
  }

  /**
   * Use the angle computed by d3 cluster but radius computed using depth of each node multiply by a constant length.
   * This is wrong since it won't guarantee that the graph will fit the radius of the graph circle.
   * The right way to do this is to calculate the constant length similar to how we calculate the radius using branch
   * length but instead in this case, all branch length are the same.
   *
   * @param d A d3 HierarchyLink that connect two node
   * @private
   */
  private linkEqual(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxDepth;
    return this.linkStep(
      d.source.x,
      d.source.depth * scaleRatio,
      d.target.x,
      d.target.depth * scaleRatio,
    );
  }

  private tipTransform(x: number, y: number) {
    return `rotate(${x}) translate(${y},0)`;
  }

  /**
   * Use the angle & radius calculated by d3 since it spread everything
   * to fit the circle with this.innerRadius as radius.
   *
   * @param d The node of the tip.
   * @private
   */
  private tipTransformCladogram(d: any) {
    return this.tipTransform(d.x, d.y);
  }

  /**
   * Use the angle calculated by d3 but radius calculated by us based on the branch length.
   *
   * @param d The node of the tip.
   * @private
   */
  private tipTransformNoTransform(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxRadius;
    return this.tipTransform(d.x, d.radius * scaleRatio);
  }

  /**
   * Use the angle calculated by d3 but radius calculated by us based on the depth of the node multiply by a constant.
   * This is wrong. We should compute this similar to how we compute the radius of the node except with the branch
   * length all equals.
   *
   * @param d The node of the tip.
   * @private
   */
  private tipTransformEqual(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxDepth;
    return this.tipTransform(d.x, d.depth * scaleRatio);
  }

  /**
   * Since x is the angle (in degree), and all label start from center (because of svg viewbox translate origin),
   * we just need to rotate to the correct angle -90 because 0 degree start from the right not top, and translate
   * to y, which the radius or distance from the center of the node. Then rotate 180 to flip the label horizontally if
   * it's on the left side of the circle (for better readability).
   *
   * @param x the angle of the node
   * @param y the radius or distance from center.
   * @private
   */
  private labelTransform(x: number, y: number) {
    const { k } = this.zoomContainer.zoomTransform;
    const { scale } = this.zoomContainer.getAbsoluteDimensionsForFit();
    const labelOffset = 8;
    const transformedLabelOffset = (labelOffset * scale) / k;
    let transform = `rotate(${x}) translate(${y + transformedLabelOffset},0)`;
    if (this.isOnLeftHandSide(x)) {
      transform += ' rotate(180)';
    }
    return transform;
  }

  /**
   * Use the angle & radius calculated by d3 since it spread everything
   * to fit the circle with this.innerRadius as radius.
   *
   * @param d The node of the label.
   * @private
   */
  private labelTransformCladogram(d: any) {
    const heatmapOffset = 5;
    const numberOfHeatmapRows = this.heatmapConfig.heatmapRowConfigs.length;
    const y =
      d.y +
      (this.heatmapConfig.show && numberOfHeatmapRows > 0
        ? numberOfHeatmapRows * this.heatmapRowHeight + heatmapOffset
        : 0);
    return this.labelTransform(d.x, y);
  }

  /**
   * Use the angle calculated by d3 but radius calculated by us based on the branch length.
   *
   * @param d The node of the label.
   * @private
   */
  private labelTransformNoTransform(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxRadius;
    return this.labelTransform(d.x, d.radius * scaleRatio);
  }

  /**
   * Use the angle calculated by d3 but radius calculated by us based on the depth of the node multiply by a constant.
   * This is wrong. We should compute this similar to how we compute the radius of the node except with the branch
   * length all equals.
   *
   * @param d The node of the label.
   * @private
   */
  private labelTransformEqual(d: any) {
    const scaleRatio = this.innerRadius / this.graphMaxDepth;
    return this.labelTransform(d.x, d.depth * scaleRatio);
  }
}

class CircularTreeDimensions extends AbsoluteDimensions {
  constructor(
    protected container: ElementRef<HTMLDivElement>,
    protected outerRadius: number,
  ) {
    super(container);
  }
  public update() {}
  public forFit() {
    const { width, height } = this.getDimensionsForZoom();
    const scaleX = Math.max(width / (this.outerRadius * 2) || 1, 0.1);
    const scaleY = Math.max(height / (this.outerRadius * 2) || 1, 0.1);
    const scale = Math.max(Math.min(scaleX, scaleY) || 1, 0.1);
    return {
      minX: -this.outerRadius,
      maxX: this.outerRadius,
      minY: -this.outerRadius,
      maxY: this.outerRadius,
      scaleX,
      scaleY,
      scale,
    };
  }
}
