import { computed, Injectable, signal } from '@angular/core';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { setGraphSidebarOptionsForGraph } from './graph-sidebar-options.actions';
import { selectSidebarOptionsForGraph } from './graph-sidebar-options.selectors';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  GraphId,
  GraphType,
  GraphTypes,
  SelectionDisplayOption,
  SelectionDisplayType,
} from '../../ngs/ngs-graphs/ngs-graphs.model';

@Injectable({
  providedIn: 'root',
})
export class GraphSidebarOptionsService {
  lastCached = signal<{ graphId: GraphId; documentId: string; options: Record<string, unknown> }>({
    graphId: 'noGraph',
    documentId: null,
    options: {},
  });
  lastGraph = computed(() => this.lastCached().graphId);
  lastOptions = computed(() => this.lastCached().options);
  lastDocument = computed(() => this.lastCached().documentId);
  lastSelectionDisplayType = signal<SelectionDisplayType>(SelectionDisplayOption.AUTO);

  constructor(private store: Store<AppState>) {}

  getLastCachedGraph(): GraphType {
    return (
      Object.values(GraphTypes).find((graph) => graph.id === this.lastGraph()) ?? GraphTypes.None
    );
  }
  getCachedOptions(graph: string, documentId: string): Record<string, any> {
    if (this.lastGraph() !== graph || this.lastDocument() !== documentId) {
      return {};
    }
    return this.lastOptions() ?? {};
  }

  setLastCachedGraph(graph: GraphId, documentId: string) {
    this.lastCached.set({ graphId: graph, documentId, options: {} });
  }

  setLastSelectionDisplayType(type: SelectionDisplayType) {
    this.lastSelectionDisplayType.set(type);
  }

  getLastSelectionDisplayType(): SelectionDisplayType {
    return this.lastSelectionDisplayType();
  }

  setCachedOptions(graph: GraphId, documentId: string, options: Record<string, any>) {
    this.lastCached.set({ graphId: graph, documentId, options });
  }

  getSavedOptions(id: string): Observable<Record<string, any>> {
    return this.store.select(selectSidebarOptionsForGraph(id)).pipe(
      filter((x) => !!x),
      take(1),
    );
  }

  setSavedOptions(id: string, options: Record<string, any>) {
    this.store.dispatch(setGraphSidebarOptionsForGraph({ id, options }));
  }
}
