import { createSelector } from '@ngrx/store';
import { selectUserSettingsState } from '../user-settings.selectors';

export const selectAllGraphSidebarOptions = createSelector(
  selectUserSettingsState,
  (state) => state?.graphSidebarOptions ?? {},
);
export const selectSidebarOptionsForGraph = (id: string) =>
  createSelector(selectAllGraphSidebarOptions, (state) => {
    return state[id] ?? {};
  });
